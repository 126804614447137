<template>
    <v-snackbar v-model="$store.state.servicesModule.snackbar.open" :color="$store.state.servicesModule.snackbar.color">
        <h-row class="px-4" align="center">
            <span>{{ $store.state.servicesModule.snackbar.text }}</span>
            <v-spacer></v-spacer>
            <v-btn color="white" icon @click="$store.dispatch('closeSnackbar')">
                <v-icon>fa-times</v-icon>
            </v-btn>
        </h-row>
    </v-snackbar>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';

    export default {
        name: 'SnackbarMessage',
        components: {HRow}
    }
</script>

<style scoped>

</style>
