const getters = {

    website: () => 'hand-2-hand.it',

    emailRegExp: () => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,

    coordinateRegExp: () => /^[+-]?([0-9]{1,2})+\.+([0-9]+)+, +[+-]?([0-9]{1,2})+\.+([0-9]+)$/,

    estensioneRegExp: () => /\.[0-9a-zA-Z]+$/,

    base64RegExp: () => /^(data:)(\w+)(\/)(\w+)(;base64,)/

};

export default getters;
