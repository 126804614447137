<template>
    <v-app-bar app dark :color="color">
        <v-btn x-large text to="/" exact>
            <v-img src="/assets/LOGO.png" max-width=60 max-height=50></v-img>
            <span style="margin-left: 10px;">HAND 2 HAND</span>
        </v-btn>
        <v-spacer></v-spacer>
        <h1 v-if="!!color">SANDBOX</h1>
        <v-spacer></v-spacer>
        <v-menu offset-y open-on-hover v-if="$store.state.authModule.token">
            <template v-slot:activator="{ on }">
                <v-btn class="px-0 px-sm-4" text v-on="on">
                    <span>{{ $store.state.authModule.utente ? $store.state.authModule.utente.NOME : '...loading' }}</span>
                    <v-icon class="ml-3" small>fa-user</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="(item, i) in items"
                             :key="i"
                             :disabled="!$store.getters.isLogged"
                             :to="item.path ? item.path : undefined"
                             exact
                             @click.stop="!item.path ? onMenuClick(item.key) : undefined"
                >
                    <v-list-item-title>
                        <span>{{ $store.getters[item.key] }}</span>
                    </v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small color="black">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'Navbar',
        computed: {
            color: function () {
                return !window.location.origin.includes('https://hand-2-hand.it') ? 'error' : undefined;
            },
            items: function () {

                const items = [
                    {
                        key: 'NAV_BO',
                        icon: 'fa-unlock-alt',
                        path: '/secure'
                    }
                ];

                if (this.$store.getters.isFromCliente) {
                    items.push(
                        {
                            key: 'NAV_CUSTOMER',
                            icon: 'fa-users',
                            path: '/secure/clienti/' + this.$store.state.authModule.utente.ID_CLIENTE_FK + '/scheda'
                        }
                    );
                }

                if (this.$store.getters.isSystem || this.$store.getters.isOperatore) {
                    items.push(
                        {
                            key: 'NAV_TASKS',
                            icon: 'fa-tasks',
                            path: '/secure/incarichi'
                        }
                    );
                }

                items.push(
                    {
                        key: 'NAV_SETTINGS',
                        icon: 'fa-user-cog',
                        path: '/secure/profile'
                    },
                    {
                        key: 'NAV_ESCI',
                        icon: 'fa-sign-out-alt',
                        path: false
                    }
                );

                return items;
            }
        },
        methods: {
            onMenuClick(key) {
                if (key === 'NAV_ESCI') {
                    this.$store.dispatch('singout');
                }
            }
        }
    }
</script>

<style scoped>
    .v-btn:before {
        background-color: transparent;
    }
</style>
