const erroriActions = {

    GET_ERRORI(ctx, idErrore) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.get('errori/' + idErrore).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    GET_ERRORI_LOG(ctx, data) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.get('errori/logs/log', {params: {data}, responseType: 'blob'}).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    }

}

export default erroriActions;
