const state = {

    //Main
    MAIN_LOADING: {
        defaultLang: 'Loading account...',
        'it': 'Caricamento utenza...',
    },

    //navigation
    MENU_TITLE: {
        defaultLang: 'Select menu',
        'it': 'Seleziona menu',
    },
    MENU_STATISTICS: {
        defaultLang: 'Statistics',
        'it': 'Statistiche',
    },
    MENU_EVENTS: {
        defaultLang: 'Events',
        'it': 'Eventi',
    },
    MENU_CITY: {
        defaultLang: 'Cities',
        'it': 'Città',
    },
    MENU_TYPOLOGIES: {
        defaultLang: 'Typologies',
        'it': 'Tipologie',
    },
    MENU_LANGUAGES: {
        defaultLang: 'Languages',
        'it': 'Lingue',
    },
    MENU_CUSTOMERS: {
        defaultLang: 'Customers',
        'it': 'Clienti',
    },
    MENU_CONTRACTS: {
        defaultLang: 'Contracts',
        'it': 'Contratti',
    },
    MENU_USERS: {
        defaultLang: 'Users',
        'it': 'Utenza',
    },
    MENU_WORKSPACE: {
        defaultLang: 'Workspace'
    },
    MENU_ERRORS: {
        defaultLang: 'Errors',
        'it': 'Errori',
    },

    //Navbar
    NAV_BO: {
        defaultLang: 'Back-Office'
    },
    NAV_CUSTOMER: {
        defaultLang: 'Customer',
        'it': 'Cliente',
    },
    NAV_TASKS: {
        defaultLang: 'Tasks',
        'it': 'Incarichi',
    },
    NAV_SETTINGS: {
        defaultLang: 'Settings',
        'it': 'Impostazioni',
    },
    NAV_ESCI: {
        defaultLang: 'Logout',
        'it': 'Disconnetti',
    },

    //Notifiche
    NOTIFY_CLEAN_ALL: {
        defaultLang: 'Click to clear all notifications',
        'it': 'Click per pulire tutte le notifiche',
    },
    NOTIFY_CLEAN_ONE: {
        defaultLang: 'Clear notification',
        'it': 'Cancella notifica',
    },
    NOTIFY_EMPTY: {
        defaultLang: 'No notifications',
        'it': 'Nessuna notifica',
    },

    //Login
    LOGIN_ACCEDI: {
        defaultLang: 'Login',
        'it': 'Accedi',
    },
    LOGIN_LOADING: {
        defaultLang: 'Logging in',
        'it': 'Accesso in corso',
    },

    //secure
    SECURE_WELCOME: {
        defaultLang: 'Welcome',
        'it': 'Benvenuto',
    },
    SECURE_HINTS: {
        defaultLang: 'Select a menu item',
        'it': 'Seleziona una voce di menu',
    },

    //Citta
    CITTA_REGION: {
        defaultLang: 'Region',
        'it': 'Regione',
    },
    CITTA_NAME: {
        defaultLang: 'Name',
        'it': 'Denominazione',
    },
    CITTA_PROVINCE: {
        defaultLang: 'Province',
        'it': 'Provincia',
    },

    //default
    DEFAULT_VIEWS: {
        defaultLang: 'Views',
        'it': 'Visualizzazioni',
    },

    //input fields
    INPUT_HINT: {
        defaultLang: 'the city',
        'it': 'la città',
    },

};

export default state;
