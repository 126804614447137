<template>
    <v-tooltip bottom>

        <template v-slot:activator="{ on }">

            <v-btn v-on="on"
                   :x-small="xSmall"
                   :large="large"
                   :block="block"
                   :fab="fab"
                   :icon="icon"
                   :color="color"
                   :min-width="minWidth"
                   :min-height="minHeight"
                   :type="type"
                   :disabled="disabled"
                   :loading="loading"
                   :to="to"
                   :exact="exact"
                   @click="$emit('click', $event)"
            >

                <v-icon v-if="!!iconName" :small="small">{{ iconName }}</v-icon>
                <span v-if="!!text" :class="'ml-' + textMargin">{{ text }}</span>

            </v-btn>

        </template>

        <slot>
            <span>{{ tooltip }}</span>
        </slot>

    </v-tooltip>
</template>

<script>
    export default {
        name: 'ButtonTooltip',
        props: {
            xSmall: Boolean,
            small: Boolean,
            large: Boolean,
            block: Boolean,
            fab: Boolean,
            icon: Boolean,
            iconName: String,
            text: [String, Number],
            textMargin: {type: [String, Number], default: 0},
            color: String,
            minWidth: [String, Number],
            minHeight: [String, Number],
            type: String,
            disabled: Boolean,
            loading: Boolean,
            to: String,
            exact: Boolean,
            tooltip: String
        }
    }
</script>

<style scoped>

</style>
