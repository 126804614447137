const utenzaActions = {

    GET_UTENZA(ctx) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.get('utenza').then(
                response => {

                    ctx.commit('SET_UTENTE', response.data);

                    resolve(response);

                },
                error => reject(error)
            );

        });

    },

    GET_INCARICHI(ctx) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.get('utenza/incarichi').then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    PUT_UTENZA_PASSWORD(ctx, {PASSWORD}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.put('utenza/password', {PASSWORD}).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    PUT_UTENZA_LINGUA(ctx, idLingua) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.put('utenza/lingua/' + idLingua).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    DELETE_INCARICHI(ctx, idIncarico) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.delete('utenza/incarichi/' + idIncarico).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    }

}

export default utenzaActions;
