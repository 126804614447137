<template>
    <h-row class="py-2">
        <v-progress-linear indeterminate></v-progress-linear>
        <h-col>
            <h-row justify="end">
                <h-col cols="auto" class="py-1">
                    <span>...caricamento</span>
                </h-col>
            </h-row>
            <h-row justify="center">
                <h-col cols="auto" class="text-center">
                    <v-icon x-large color="black" style="font-size: 100px;">{{ icon }}</v-icon>
                    <h1 class="pa-2">{{ messaggio }}</h1>
                </h-col>
            </h-row>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'LoadingResouces',
        components: {HRow, HCol},
        props: {
            icon: String,
            messaggio: String
        }
    }
</script>

<style scoped>

</style>
