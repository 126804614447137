import Axios from 'axios';

const getters = {

    axios: state => Axios.create(
        {
            baseURL: window.location.origin === 'http://localhost:8089' ? 'http://localhost:3000/api/' : window.location.origin + '/api/',
            headers: {
                'Authorization': 'Bearer ' + state.token,
                'X-VERSIONE': 150,
                'X-DEVICE': 'DEFAULT'
            }
        }
    ),

    isLogged: state => !!state.utente && !!state.token,

    isSystem: state => state.utente.PROFILO === 'SISTEMA',
    isOperatore: state => state.utente.PROFILO === 'OPERATORE',
    isFromCliente: state => state.utente.PROFILO === 'CLIENTE',
    isCommercialista: state => state.utente.PROFILO === 'COMMERCIALISTA',
    isChangePassword: state => state.utente.PROFILO === 'PASSWORD',
    isGuest: state => state.utente.PROFILO === 'MOBILE',

    unauthorized: (state, getters) => {

        const unauthorized = [];

        //BLOCCHI IN BASE AL PROFILO

        unauthorized.push('/secure/citta/form');

        if (!getters.isSystem) {

            unauthorized.push('/secure/tipologie/form');
            unauthorized.push('/secure/lingue/form');
            unauthorized.push('/secure/workspace');
            unauthorized.push('/secure/workspace/form');
            unauthorized.push('/secure/workspace/scheda');
            unauthorized.push('/secure/errori');

        }

        if (!getters.isSystem && !getters.isOperatore) {

            unauthorized.push('/secure/incarichi');
            unauthorized.push('/secure/utenti');
            unauthorized.push('/secure/utenti/form');
            unauthorized.push('/secure/utenti/scheda');

        }

        if (!getters.isSystem && !getters.isOperatore && !getters.isFromCliente) {

            unauthorized.push('/secure/citta');
            unauthorized.push('/secure/citta/scheda');
            unauthorized.push('/secure/tipologie');
            unauthorized.push('/secure/tipologie/scheda');
            unauthorized.push('/secure/lingue');
            unauthorized.push('/secure/lingue/scheda');
            unauthorized.push('/secure/eventi');
            unauthorized.push('/secure/eventi/form');
            unauthorized.push('/secure/eventi/scheda');
            unauthorized.push('/secure/percorsi');
            unauthorized.push('/secure/percorsi/form');
            unauthorized.push('/secure/percorsi/scheda');

        }

        if (!getters.isSystem && !getters.isOperatore && !getters.isCommercialista) {

            unauthorized.push('/secure/clienti');
            unauthorized.push('/secure/clienti/form');
            unauthorized.push('/secure/clienti/scheda');
            unauthorized.push('/secure/contratti');
            unauthorized.push('/secure/contratti/form');
            unauthorized.push('/secure/contratti/scheda');

        }

        if (!getters.isSystem && !getters.isOperatore && !getters.isFromCliente && !getters.isCommercialista) {

            unauthorized.push('/secure');
            unauthorized.push('/secure/profile');

        }

        //BLOCCHI IN BASE AI POTERI

        if (!state.utente.CAN_EVENTO) {
            unauthorized.push('/secure/eventi');
            unauthorized.push('/secure/eventi/scheda');
        }

        if (!state.utente.CAN_CREATE_EVENTO && !state.utente.CAN_EDIT_EVENTO) {
            unauthorized.push('/secure/eventi/form');
        }

        if (!state.utente.CAN_CREATE_EVENTO) {
            unauthorized.push('/secure/eventi/form?');
        }

        if (!state.utente.CAN_EDIT_EVENTO) {
            unauthorized.push('/secure/eventi/form?id=');
        }

        if (!state.utente.CAN_UTENTE) {
            unauthorized.push('/secure/utenti');
            unauthorized.push('/secure/utenti/scheda');
        }

        if (!state.utente.CAN_CREATE_UTENTE && !state.utente.CAN_EDIT_UTENTE) {
            unauthorized.push('/secure/utenti/form');
        }

        if (!state.utente.CAN_CREATE_UTENTE) {
            unauthorized.push('/secure/utenti/form?');
        }

        if (!state.utente.CAN_EDIT_UTENTE) {
            unauthorized.push('/secure/utenti/form?id=');
        }

        if (!state.utente.CAN_CLIENTE) {
            unauthorized.push('/secure/clienti');
            unauthorized.push('/secure/clienti/scheda');
            unauthorized.push('/secure/contratti');
            unauthorized.push('/secure/contratti/scheda');
        }

        if (!state.utente.CAN_CREATE_CLIENTE && !state.utente.CAN_EDIT_CLIENTE) {
            unauthorized.push('/secure/clienti/form');
        }

        if (!state.utente.CAN_CREATE_CLIENTE) {
            unauthorized.push('/secure/clienti/form?');
        }

        if (!state.utente.CAN_EDIT_CLIENTE) {
            unauthorized.push('/secure/clienti/form?id=');
        }

        if (!state.utente.CAN_CONTRATTO) {
            unauthorized.push('/secure/contratti');
            unauthorized.push('/secure/contratti/scheda');
        }

        if (!state.utente.CAN_CREATE_CONTRATTO && !state.utente.CAN_EDIT_CONTRATTO) {
            unauthorized.push('/secure/contratti/form');
        }

        if (!state.utente.CAN_CREATE_CONTRATTO) {
            unauthorized.push('/secure/contratti/form?');
        }

        if (!state.utente.CAN_EDIT_CONTRATTO) {
            unauthorized.push('/secure/contratti/form?id=');
        }

        return unauthorized;

    }

};

export default getters;
