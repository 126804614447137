import baseRequestActions from '@/store/module/api/subActions/baseRequest';
import eventiActions from '@/store/module/api/subActions/impl/eventi';
import lingueActions from '@/store/module/api/subActions/impl/lingue';
import utentiActions from '@/store/module/api/subActions/impl/utenti';
import utenzaActions from '@/store/module/api/subActions/impl/utenza';
import erroriActions from '@/store/module/api/subActions/impl/errori';

const actions = {
    ...baseRequestActions,
    ...eventiActions,
    ...lingueActions,
    ...utentiActions,
    ...utenzaActions,
    ...erroriActions
};

export default actions;
