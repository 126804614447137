<template>
    <v-app>

        <navbar></navbar>

        <v-main>

            <v-container fluid class="pa-0">

                <h-row v-show="!$store.state.servicesModule.internalServerError">

                    <h-col cols="12" md="auto" v-if="isNavigationDrawer" no-padding>
                        <navigation-drawer></navigation-drawer>
                    </h-col>

                    <h-col no-padding>

                        <router-view v-if="isRouterView"></router-view>

                        <loading-resouces v-else-if="isLoadingUtenza"
                                          icon="fa-user"
                                          :messaggio="$store.getters.MAIN_LOADING"
                        ></loading-resouces>

                        <unauthorized v-else-if="isUnatorized"></unauthorized>

                    </h-col>

                </h-row>

                <internal-server-error v-show="$store.state.servicesModule.internalServerError"></internal-server-error>
                <snackbar-message></snackbar-message>
                <menu-notifiche v-if="$store.state.authModule.token"></menu-notifiche>

            </v-container>

        </v-main>

        <change-password-dialog :dialog="$store.getters.isChangePassword"
                                v-if="$store.getters.isChangePassword"
        ></change-password-dialog>

    </v-app>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import Navbar from '@/components/navigation/Navbar';
    import NavigationDrawer from '@/components/navigation/NavigationDrawer';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import Unauthorized from '@/components/aaaGenerics/commons/Unauthorized';
    import InternalServerError from '@/components/aaaGenerics/commons/InternalServerError';
    import SnackbarMessage from '@/components/aaaGenerics/commons/SnackbarMessage';
    import MenuNotifiche from '@/components/navigation/MenuNotifiche';
    import ChangePasswordDialog from '@/components/aaaProject/commons/ChangePasswordDialog';

    export default {
        name: 'App',
        components: {
            HCol,
            HRow,
            ChangePasswordDialog,
            MenuNotifiche,
            SnackbarMessage,
            InternalServerError,
            Unauthorized,
            LoadingResouces,
            NavigationDrawer,
            Navbar
        },
        computed: {
            website: function () {
                return this.$store.getters.website;
            },
            isAutorizzato: function () {

                return this.$store.state.authModule.utente &&
                       !this.$store.getters.unauthorized.includes(this.$route.path);

            },
            isNavigationDrawer: function () {

                return this.$route.path !== '/' &&
                       this.$store.state.authModule.token &&
                       this.$route.path.includes('/secure');

            },
            isRouterView: function () {

                return this.$route.path === '/login' ||
                       this.$route.path === '/' ||
                       this.$route.fullPath.includes('/app') ||
                       this.isAutorizzato;

            },
            isLoadingUtenza: function () {

                return this.$route.path !== '/login' &&
                       this.$route.fullPath.includes('/secure') &&
                       !this.$store.state.authModule.utente;

            },
            isUnatorized: function () {

                return this.$route.path !== '/login' &&
                       this.$route.fullPath.includes('/secure') &&
                       !this.isAutorizzato;

            }
        },
        created() {
            if (!window.location.origin.includes(this.website) || !location.origin.includes(this.website)) {
                // location.href = 'https://' + this.website;
            }
        }
    };
</script>
