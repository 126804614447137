var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},on:{"click:outside":function($event){$event.stopPropagation();!_vm.isLoading && _vm.canClose && _vm.$emit('close')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }!_vm.isLoading && _vm.canClose && _vm.$emit('close')}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h-row',{attrs:{"justify":"center"}},[_c('h-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v("Cambia Password")])])],1)],1),_c('v-card-subtitle',{staticClass:"pb-0"},[_c('h-row',{attrs:{"justify":"center"}},[_c('h-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v("Inserire nuova password")])])],1)],1),_c('v-divider'),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('h-row',{attrs:{"justify":"center"}},[_c('h-col',{staticClass:"pa-4",attrs:{"cols":"11","no-padding":""}},[_c('base-text-field',{attrs:{"type":_vm.isNuovaPasswordShow ? 'text' : 'password',"label":"Nuova Password","placeholder":"Nuova Password","hide-details":false,"rules":[
                                             function (v) { return !!v || 'La password è obbligatoria!'; },
                                             function (v) { return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(v) ||
                                                   'La password deve essere lunga almeno 8 caratteri, contenere una maiuscola, un numero e carattere speciale [!@#$%^&*]'; }
                                         ],"append-icon":_vm.isNuovaPasswordShow ? 'fa-eye-slash' : 'fa-eye'},on:{"appendClick":function($event){_vm.isNuovaPasswordShow = !_vm.isNuovaPasswordShow}},model:{value:(_vm.user.PASSWORD),callback:function ($$v) {_vm.$set(_vm.user, "PASSWORD", $$v)},expression:"user.PASSWORD"}}),_c('base-text-field',{staticClass:"mt-4",attrs:{"type":_vm.isConfermaPasswordShow ? 'text' : 'password',"label":"Conferma Password","placeholder":"Conferma Password","hide-details":false,"rules":[
                                             function (v) { return !!v || 'La conferma password è obbligatoria!'; },
                                             function (v) { return v === _vm.user.PASSWORD || 'Le password non corrispondono'; }
                                         ],"append-icon":_vm.isConfermaPasswordShow ? 'fa-eye-slash' : 'fa-eye'},on:{"appendClick":function($event){_vm.isConfermaPasswordShow = !_vm.isConfermaPasswordShow}},model:{value:(_vm.user.CONFERMA),callback:function ($$v) {_vm.$set(_vm.user, "CONFERMA", $$v)},expression:"user.CONFERMA"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"large":"","color":_vm.valid ? 'success' : 'error',"type":"submit","loading":_vm.isLoading || _vm.isGlobalLoading}},[_c('span',{staticClass:"ma-2"},[_vm._v("SALVA")])]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }