import cookie from 'vue-cookies';

const actions = {

    activateGlobalLoading(ctx) {
        ctx.commit('SET_IS_GLOBAL_LOADING', true);
    },
    deactivateGlobalLoading(ctx) {
        ctx.commit('SET_IS_GLOBAL_LOADING', false);
    },

    returnFromInternalError(ctx) {
        ctx.commit('SET_INTERNAL_SERVER_ERROR', false);
    },

    activeSnackbar(ctx, {text, color}) {

        ctx.commit('SET_SNACKBAR', {open: true, text, color: color ? color : 'primary'});

        ctx.dispatch('addNotifica', {text, color});

    },
    closeSnackbar(ctx) {
        ctx.commit('SET_SNACKBAR', {...ctx.state.snackbar, open: false});
    },

    loadNotifiche(ctx) {

        const notifiche = JSON.parse(cookie.get('notifiche'));

        if (notifiche && notifiche.length) {
            ctx.commit('SET_NOTIFICHE', notifiche);
        }

    },
    addNotifica(ctx, {text, color}) {

        ctx.commit('SET_NOTIFICHE', [
            {data: new Date(), text: text, color: color ? color : 'primary', showed: false},
            ...ctx.state.notifiche
        ]);

        cookie.set('notifiche', JSON.stringify(ctx.state.notifiche), '1y', '/');

    },
    showAllNotifiche(ctx) {

        ctx.commit('SET_NOTIFICHE', ctx.state.notifiche.map(n => ({...n, showed: true})));

        cookie.set('notifiche', JSON.stringify(ctx.state.notifiche), '1y', '/');

    },
    pulisciNotifiche(ctx) {

        ctx.commit('SET_NOTIFICHE', []);

        cookie.remove('notifiche', '/');

    },
    removeNotifica(ctx, {index}) {

        ctx.commit('SET_NOTIFICHE', ctx.state.notifiche.filter((n, i) => i !== index));

        cookie.set('notifiche', JSON.stringify(ctx.state.notifiche), '1y', '/');

    }

};

export default actions;
