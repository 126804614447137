const getters = {

    dLng: () => 'defaultLang',

    cLingua: (state, getters, ctx) => {

        if (!ctx.authModule.utente || !ctx.authModule.utente.ID_LINGUA_FK) {
            return getters.dLng;
        }

        return {
            1: getters.dLng,
            2: 'it',
            3: 'fr',
            4: 'es',
            5: 'de'
        }[ctx.authModule.utente.ID_LINGUA_FK];

    }

};

import state from './state';

for (const key of Object.keys(state)) {
    getters[key] = (state, getters) => !state[key][getters.cLingua] ? state[key][getters.dLng] : state[key][getters.cLingua];
}

export default getters;
