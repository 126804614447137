import cookie from 'vue-cookies';

const baseRequestActions = {

    defaultRequest(ctx, {api, paylod, doResponse, doClose}) {

        ctx.dispatch('activateGlobalLoading');

        ctx.dispatch(api, paylod).then(
            response => {
                cookie.set('token', response.headers.jsonwebtoken, '3h', '/');
                ctx.dispatch('deactivateGlobalLoading');
                doResponse(response.data);
                doClose(true);
            },
            error => {
                ctx.dispatch('error', error);
                ctx.dispatch('deactivateGlobalLoading');
                doClose(false);
            }
        );

    },

    error(ctx, error) {

        if (!error.response || error.response.status >= 500 || error.response.status === 404) {

            ctx.commit('SET_INTERNAL_SERVER_ERROR', true);

        } else if (error.response.status === 401) {

            ctx.dispatch('activeSnackbar', {text: 'Sessione scaduta, accedi nuovamente', color: 'error'});

            const params = location.href.split(/\?/)[1];
            ctx.dispatch(
                'singout',
                location.pathname === '/login' ? false : ('rdr=' + location.pathname + (params ? '&' + params : ''))
            );

        } else {
            ctx.dispatch('activeSnackbar', {text: error.response.data, color: 'error'});
        }

    },

    GET_BASE(ctx, {modelsName, path, options}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.get(path ? path : modelsName.toLowerCase(), {
                params: {
                    ...options,
                    page: options.page - (options.page > 0 ? 1 : 0),
                    perPage: options.itemsPerPage,
                    sortColumn: options.sortBy && options.sortBy.length ? options.sortBy[0].toUpperCase() : null,
                    sortDirection: options.sortDesc && options.sortDesc.length && options.sortDesc[0] ? 'DESC' : 'ASC',
                    itemsPerPage: null,
                    sortBy: null,
                    sortDesc: null
                }
            }).then(
                response => {
                    ctx.commit('SET_' + modelsName.toUpperCase() + '_SIZE', response.data.totalCount);
                    ctx.commit('SET_' + modelsName.toUpperCase(), response.data.items);
                    resolve(response);
                },
                error => reject(error)
            );

        });

    },

    GET_BASE_SINGLE(ctx, {modelsName, id}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.get(modelsName.toLowerCase() + '/' + id).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    POST_BASE(ctx, {modelsName, model}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.post(modelsName.toLowerCase(), model).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    PUT_BASE(ctx, {modelsName, model, id}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.put(modelsName.toLowerCase() + '/' + (!id ? model.ID_REC : id), model).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    PUT_BASE_TOGGLE(ctx, {modelsName, id}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.put(modelsName.toLowerCase() + '/' + id + '/toggle').then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    DELETE_BASE(ctx, {modelsName, id, isDropCascade}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.delete(modelsName.toLowerCase() + '/' + id, {params: {isDropCascade}}).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    }

}

export default baseRequestActions;
