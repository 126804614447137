<template>
    <v-text-field v-on="$listeners"
                  :outlined="outlined"
                  v-model="v"
                  :type="type"
                  :label="label"
                  :placeholder="placeholder"
                  :persistent-placeholder="persistentPlaceholder"
                  :hint="hint"
                  :hide-details="hideDetails === undefined ? !hint : hideDetails"
                  :persistent-hint="persistentHint"
                  :clearable="clearable"
                  :counter="counter"
                  :maxlength="maxlength"
                  :readonly="readonly"
                  :disabled="disabled"
                  :loading="loading"
                  :full-width="fullWidth"
                  :rules="rules"
                  :prepend-icon="prependIcon"
                  :prepend-inner-icon="prependInnerIcon"
                  :append-icon="appendIcon"
                  @input="$emit('input', $event)"
                  @click:append="$emit('appendClick', $event)"
    ></v-text-field>
</template>

<script>
    export default {
        name: 'BaseTextField',
        props: {
            outlined: {type: Boolean, default: true},
            value: [String, Number],
            type: String,
            label: String,
            placeholder: String,
            persistentPlaceholder: {type: Boolean, default: true},
            hint: String,
            hideDetails: {type: Boolean, default: undefined},
            persistentHint: {type: Boolean, default: true},
            clearable: Boolean,
            counter: {type: Boolean, default: true},
            maxlength: [Number, String],
            readonly: Boolean,
            disabled: Boolean,
            loading: Boolean,
            fullWidth: Boolean,
            rules: Array,
            prependIcon: String,
            prependInnerIcon: String,
            appendIcon: String
        },
        watch: {
            value: function () {
                this.v = this.value;
            }
        },
        data: () => ({
            v: undefined
        }),
        created() {
            this.v = this.value;
        }
    }
</script>

<style scoped>

</style>
