const mutations = {
    SET_TOKEN(state, value) {
        state.token = value;
    },
    SET_UTENTE(state, value) {
        state.utente = value;
    }
};

export default mutations;
