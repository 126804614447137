const lingueActions = {

    PUT_LINGUA_DEFAULT(ctx, idLingua) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.put('lingue/' + idLingua + '/default').then(
                response => resolve(response),
                error => reject(error)
            );

        });

    }

}

export default lingueActions;
