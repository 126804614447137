import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router';
import cookie from 'vue-cookies';

Vue.config.productionTip = false;

import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyC-BfsVCq2F94c4kTHbzoxZCD-H0aypHdw',
        libraries: 'places'
    }
});

import VueQRCodeComponent from 'vue-qrcode-component';

Vue.component('qr-code', VueQRCodeComponent);

new Vue(
    {
        vuetify,
        store,
        router,
        cookie,
        render: h => h(App)
    }
).$mount('#app');
