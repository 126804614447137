const state = {

    citta: [],
    cittaSize: 0,

    clienti: [],
    clientiSize: 0,

    contratti: [],
    contrattiSize: 0,

    eventi: [],
    eventiSize: 0,

    lingue: [],
    lingueSize: 0,

    tipologie: [],
    tipologieSize: 0,

    utenti: [],
    utentiSize: 0,

    workspace: [],
    workspaceSize: 0,

    errori: [],
    erroriSize: 0
};

export default state;
