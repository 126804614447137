<template>
    <v-row no-gutters :class="eClass" :style="eStyle" :justify="justify" :align="align">
        <slot></slot>
    </v-row>
</template>

<script>
    export default {
        name: 'HRow',
        props: {
            eClass: {type: String, default: ''},
            eStyle: {type: String, default: ''},
            justify: {type: String, default: null},
            align: {type: String, default: null}
        }
    }
</script>

<style scoped>

</style>
