import cookie from 'vue-cookies';

const actions = {

    singin(ctx, user) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.post('utenza/', user).then(
                response => {

                    ctx.commit('SET_UTENTE', response.data);

                    if (ctx.getters.isGuest) {

                        response.data = 'L\'utenza GUEST non è abilitata al BackOffice';

                        reject({response});

                        ctx.dispatch('singout', location.href.split(/\?/)[1]);

                    }

                    resolve(response);

                },
                error => reject(error)
            );

        });

    },

    singout(ctx, params) {
        cookie.remove('token', '/');
        ctx.commit('SET_TOKEN', false);
        ctx.commit('SET_UTENTE', false);
        location.href = '/login' + (params ? '?' + params : '');
    }

};

export default actions;
