const getters = {

    menus: (state, getters) => {

        const menus = [];

        const doCheckMenu = ({key, icon, router}) => {
            if (!getters.unauthorized.includes(router)) {
                menus.push({key, icon, router});
            }
        }

        doCheckMenu({key: 'MENU_STATISTICS', icon: 'fa-chart-bar', router: '/secure'});
        doCheckMenu({key: 'MENU_EVENTS', icon: 'fa-calendar-alt', router: '/secure/eventi'});
        doCheckMenu({key: 'MENU_CITY', icon: 'fa-university', router: '/secure/citta'});
        doCheckMenu({key: 'MENU_TYPOLOGIES', icon: 'fa-boxes', router: '/secure/tipologie'});
        doCheckMenu({key: 'MENU_LANGUAGES', icon: 'fa-flag-checkered', router: '/secure/lingue'});
        doCheckMenu({key: 'MENU_CUSTOMERS', icon: 'fa-users', router: '/secure/clienti'});
        doCheckMenu({key: 'MENU_CONTRACTS', icon: 'fa-file-lines', router: '/secure/contratti'});
        doCheckMenu({key: 'MENU_USERS', icon: 'fa-users-cog', router: '/secure/utenti'});
        doCheckMenu({key: 'MENU_WORKSPACE', icon: 'fa-laptop-house', router: '/secure/workspace'});
        doCheckMenu({key: 'MENU_ERRORS', icon: 'fa-bug', router: '/secure/errori'});

        return menus;

    }

};

export default getters;
