<template>
    <v-navigation-drawer permanent width="100%">
        <v-list>

            <v-list-item class="my-4">
                <h-row class="px-4" justify="center">
                    <h2 class="hidden-sm-and-down">{{ $store.getters.MENU_TITLE }}</h2>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn dark width="100%" v-on="on" class="hidden-md-and-up">
                                <h1 class="pr-4">MENU</h1>
                                <v-icon>fa-bars</v-icon>
                            </v-btn>
                        </template>
                        <menu-list></menu-list>
                    </v-menu>
                </h-row>
            </v-list-item>

            <v-divider></v-divider>

            <menu-list class="hidden-sm-and-down"></menu-list>

        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import MenuList from '@/components/navigation/MenuList';

    export default {
        name: 'NavigationDrawer',
        components: {HRow, MenuList}
    }
</script>

<style scoped>

</style>
