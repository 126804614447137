const eventiActions = {

    GET_EVENTO_GALLERIA(ctx, {idEvento, idGalleria, COVER}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.get('eventi/' + idEvento + '/galleria/' + idGalleria, {responseType: 'blob', params: {COVER}}).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    GET_EVENTO_INCARICHI(ctx, {idEvento}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.get('eventi/' + idEvento + '/incarichi/').then(
                response => resolve(response),
                error => reject(error)
            );

        });

    },

    PUT_EVENTO_INCARICO(ctx, {idEvento, incarico}) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.put('eventi/' + idEvento + '/incarichi/', incarico).then(
                response => resolve(response),
                error => reject(error)
            );

        });

    }

};

export default eventiActions;
