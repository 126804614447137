<template>
    <v-col :class="finalClass" :style="eStyle" :cols="cols" :sm="sm" :md="md" :lg="lg">
        <slot></slot>
    </v-col>
</template>

<script>
    export default {
        name: 'HCol',
        props: {
            eClass: {type: String, default: ''},
            eStyle: {type: String, default: ''},
            noPadding: {type: Boolean, default: false},
            cols: {type: String, default: null},
            sm: {type: String, default: null},
            md: {type: String, default: null},
            lg: {type: String, default: null}
        },
        computed: {
            finalClass: function () {

                let eClass = this.eClass + (!this.eClass ? '' : ' ');

                if (!this.noPadding) {
                    eClass += 'pa-3';
                }

                return eClass;

            }
        }
    }
</script>

<style scoped>

</style>
