const utentiActions = {

    PUT_UTENTE_PASSWORD(ctx, idUtente) {

        return new Promise((resolve, reject) => {

            ctx.getters.axios.put('utenti/' + idUtente + '/reset').then(
                response => resolve(response),
                error => reject(error)
            );

        });

    }

}

export default utentiActions;
