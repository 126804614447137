<template>
    <v-list>
        <v-list-item class="px-0" v-for="menu in $store.getters.menus" :key="menu.key">
            <v-btn x-large
                   text
                   block
                   :disabled="menu.isBlocked"
                   :to="menu.router"
                   :exact="menu.router === '/secure'"
            >
                <h-row align="center">
                    <h-col cols="2" no-padding>
                        <v-icon>{{ menu.icon }}</v-icon>
                    </h-col>
                    <h-col cols="8">
                        <span>{{ $store.getters[menu.key] }}</span>
                    </h-col>
                    <h-col cols="2">
                        <v-icon v-if="menu.isBlocked">fa-lock</v-icon>
                    </h-col>
                </h-row>
            </v-btn>
        </v-list-item>
    </v-list>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'MenuList',
        components: {HRow, HCol}
    }
</script>

<style scoped>

</style>
