const mutations = {

    SET_CITTA(state, citta) {
        state.citta = citta;
    },
    SET_CITTA_SIZE(state, size) {
        state.cittaSize = size;
    },

    SET_CLIENTI(state, clienti) {
        state.clienti = clienti;
    },
    SET_CLIENTI_SIZE(state, size) {
        state.clientiSize = size;
    },

    SET_CONTRATTI(state, contratti) {
        state.contratti = contratti;
    },
    SET_CONTRATTI_SIZE(state, size) {
        state.contrattiSize = size;
    },

    SET_EVENTI(state, eventi) {
        state.eventi = eventi;
    },
    SET_EVENTI_SIZE(state, size) {
        state.eventiSize = size;
    },

    SET_LINGUE(state, lingue) {
        state.lingue = lingue;
    },
    SET_LINGUE_SIZE(state, size) {
        state.lingueSize = size;
    },

    SET_TIPOLOGIE(state, tipologie) {
        state.tipologie = tipologie;
    },
    SET_TIPOLOGIE_SIZE(state, size) {
        state.tipologieSize = size;
    },

    SET_UTENTI(state, utenti) {
        state.utenti = utenti;
    },
    SET_UTENTI_SIZE(state, size) {
        state.utentiSize = size;
    },

    SET_WORKSPACE(state, workspace) {
        state.workspace = workspace;
    },
    SET_WORKSPACE_SIZE(state, size) {
        state.workspaceSize = size;
    },

    SET_ERRORI(state, errori) {
        state.errori = errori;
    },
    SET_ERRORI_SIZE(state, size) {
        state.erroriSize = size;
    }

};

export default mutations;
