const getters = {

    roleTypes: () => [
        {
            titolo: 'Eventi',
            items: [
                {nome: 'ACCEDERE ALLA SEZIONE', type: 'GRADO', block: true},
                {nome: 'CREARE', type: 'GRADO', block: true},
                {nome: 'MODIFICARE', type: 'GRADO', block: true},
                {nome: 'ABILITARE', type: 'CAN_ENABLE_EVENTO'},
                {nome: 'ABILITARE SUGGERITO', type: 'CAN_SUGGERITO_EVENTO'},
                {nome: 'ELIMINARE', type: 'CAN_DELETE_EVENTO'},
                {nome: 'MODIFICARE ALTRI', type: 'CAN_OTHER_EVENTO'}
            ]
        },
        {
            titolo: 'Clienti',
            items: [
                {nome: 'ACCEDERE ALLA SEZIONE', type: 'CAN_CLIENTE'},
                {nome: 'CREARE', type: 'CAN_CREATE_CLIENTE'},
                {nome: 'MODIFICARE', type: 'CAN_EDIT_CLIENTE'},
                {nome: 'ABILITARE', type: 'CAN_ENABLE_CLIENTE'},
                {nome: 'ELIMINARE', type: 'CAN_DELETE_CLIENTE'},
                {nome: 'MODIFICARE ALTRI', type: 'CAN_OTHER_CLIENTE'}
            ]
        },
        {
            titolo: 'Utenza',
            items: [
                {nome: 'ACCEDERE ALLA SEZIONE', type: 'CAN_UTENTE'},
                {nome: 'CREARE', type: 'CAN_CREATE_UTENTE'},
                {nome: 'MODIFICARE', type: 'CAN_EDIT_UTENTE'},
                {nome: 'ABILITARE', type: 'CAN_ENABLE_UTENTE'},
                {nome: 'ELIMINARE', type: 'CAN_DELETE_UTENTE'},
                {nome: 'MODIFICARE ALTRI', type: 'CAN_OTHER_UTENTE'}
            ]
        },
        {
            titolo: 'Ruoli',
            items: [
                {nome: 'ACCEDERE ALLA SEZIONE', type: 'CAN_RUOLO'},
                {nome: 'CREARE', type: 'CAN_CREATE_RUOLO'},
                {nome: 'MODIFICARE', type: 'CAN_EDIT_RUOLO'},
                {nome: 'ELIMINARE', type: 'CAN_DELETE_RUOLO'},
                {nome: 'MODIFICARE ALTRI', type: 'CAN_OTHER_RUOLO'}
            ]
        }
    ]

};

export default getters;
