<template>
    <v-dialog v-model="dialog"
              persistent
              max-width="400px"
              @click:outside.stop="!isLoading && canClose && $emit('close')"
              @keydown.esc="!isLoading && canClose && $emit('close')"
    >
        <v-card>

            <v-card-title>
                <h-row justify="center">
                    <h-col cols="auto">
                        <h2>Cambia Password</h2>
                    </h-col>
                </h-row>
            </v-card-title>

            <v-card-subtitle class="pb-0">
                <h-row justify="center">
                    <h-col cols="auto">
                        <span>Inserire nuova password</span>
                    </h-col>
                </h-row>
            </v-card-subtitle>

            <v-divider></v-divider>

            <v-form v-model="valid" ref="form" @submit.prevent="onSave">
                <v-card-text>
                    <h-row justify="center">
                        <h-col cols="11" class="pa-4" no-padding>
                            <base-text-field v-model="user.PASSWORD"
                                             :type="isNuovaPasswordShow ? 'text' : 'password'"
                                             label="Nuova Password"
                                             placeholder="Nuova Password"
                                             :hide-details="false"
                                             :rules="[
                                                 v => !!v || 'La password è obbligatoria!',
                                                 v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(v) ||
                                                       'La password deve essere lunga almeno 8 caratteri, contenere una maiuscola, un numero e carattere speciale [!@#$%^&*]'
                                             ]"
                                             :append-icon="isNuovaPasswordShow ? 'fa-eye-slash' : 'fa-eye'"
                                             @appendClick="isNuovaPasswordShow = !isNuovaPasswordShow"
                            ></base-text-field>
                            <base-text-field v-model="user.CONFERMA"
                                             :type="isConfermaPasswordShow ? 'text' : 'password'"
                                             class="mt-4"
                                             label="Conferma Password"
                                             placeholder="Conferma Password"
                                             :hide-details="false"
                                             :rules="[
                                                 v => !!v || 'La conferma password è obbligatoria!',
                                                 v => v === user.PASSWORD || 'Le password non corrispondono'
                                             ]"
                                             :append-icon="isConfermaPasswordShow ? 'fa-eye-slash' : 'fa-eye'"
                                             @appendClick="isConfermaPasswordShow = !isConfermaPasswordShow"
                            ></base-text-field>
                        </h-col>
                    </h-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn large :color="valid ? 'success' : 'error'" type="submit" :loading="isLoading || isGlobalLoading">
                        <span class="ma-2">SALVA</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-form>

        </v-card>
    </v-dialog>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';

    export default {
        name: 'ChangePasswordDialog',
        components: {HRow, HCol, BaseTextField},
        props: {dialog: Boolean, canClose: Boolean},
        data: () => ({
            valid: false,
            isLoading: false,
            apiTimer: null,
            user: null,
            isNuovaPasswordShow: false,
            isConfermaPasswordShow: false
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            }
        },
        methods: {
            onSave() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //PUT nuova password
                        _this.$store.dispatch('defaultRequest', {
                            api: 'PUT_UTENZA_PASSWORD',
                            paylod: _this.user,
                            doResponse: () => {

                                //GET dell'utenza aggiornata
                                _this.$store.dispatch('defaultRequest', {
                                    api: 'GET_UTENZA',
                                    paylod: {},
                                    doResponse: () => _this.$store.dispatch('activeSnackbar', {
                                        color: 'success',
                                        text: 'Password aggiornata con successo'
                                    }),
                                    doClose: () => {
                                        _this.isLoading = false;
                                        _this.$emit('close');
                                    }
                                });

                            },
                            doClose: isOk => _this.isLoading = isOk
                        });
                    }
                }

                if (this.$refs.form.validate()) {
                    this.isLoading = true;
                    this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                    doRequest(this);
                }
            }
        },
        created() {
            this.user = {
                PASSWORD: '',
                CONFERMA: ''
            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
