<template>
    <v-menu offset-y left max-width="350" max-height="400" rounded @input="onOpenMenu">
        <template v-slot:activator="{ on }">
            <v-btn fab v-on="on" fixed bottom right color="primary" small class="mr-4">
                <v-badge color="error" :content="notificheAttive.toString()">
                    <v-icon small>fa-bell</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <h-row class="white" style="width: 350px; height: 400px;">
            <h-col cols="12" no-padding>

                <h-row justify="center">
                    <h-col class="pb-0" cols="12">
                        <button-tooltip block
                                        icon-name="fa-trash"
                                        :color="notifiche.length > 0 ? 'primary' : 'error'"
                                        :tooltip="$store.getters.NOTIFY_CLEAN_ALL"
                                        @click="onPulisciNotifiche"
                        ></button-tooltip>
                    </h-col>
                </h-row>

                <h-row v-if="notifiche.length > 0" justify="center">
                    <h-col cols="12" no-padding>

                        <v-list class="pa-3 py-2">
                            <v-list-item v-for="(item, i) in notifiche"
                                         :key="i"
                                         class="my-1"
                                         :class="item.color ? item.color : 'primary'"
                                         style="border-radius: 4px;"
                            >
                                <v-list-item-content class="white--text">
                                    <span style="font-size: 10px;">{{ getData(item.data) }}</span>
                                    <span style="word-break: break-word;">{{ item.text }}</span>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <button-tooltip icon
                                                    icon-name="fa-times"
                                                    :tooltip="$store.getters.NOTIFY_CLEAN_ONE"
                                                    color="white"
                                                    @click.stop="onCancellaNotifica(i)"
                                    ></button-tooltip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </h-col>
                </h-row>

                <h-row v-else class="pt-6" justify="center" align="center">
                    <h-col cols="auto" no-padding>
                        <i>{{ $store.getters.NOTIFY_EMPTY }}</i>
                    </h-col>
                </h-row>

            </h-col>
        </h-row>
    </v-menu>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import moment from '@/moment';

    export default {
        name: 'MenuNotifiche',
        components: {HRow, HCol, ButtonTooltip},
        computed: {
            notifiche: function () {
                return this.$store.state.servicesModule.notifiche;
            },
            notificheAttive: function () {
                return this.notifiche.filter(n => !n.showed).length;
            }
        },
        methods: {
            onOpenMenu(opened) {
                if (opened) {
                    this.$store.dispatch('showAllNotifiche');
                }
            },
            onPulisciNotifiche() {
                this.$store.dispatch('pulisciNotifiche');
            },
            onCancellaNotifica(index) {
                this.$store.dispatch('removeNotifica', {index});
            },
            getData(data) {
                return moment.toDate(data);
            }
        },
        created() {
            this.$store.dispatch('loadNotifiche');
        }
    }
</script>

<style scoped>

</style>
