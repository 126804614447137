import Vue from 'vue';
import Router from 'vue-router';
import routes from './config';
import store from '../store';
import cookie from 'vue-cookies';

Vue.use(Router);

const router = new Router(
    {
        mode: 'history',
        base: process.env.BASE_URL,
        routes: routes
    }
);

router.beforeEach((to, from, next) => {

    store.commit('SET_TOKEN', cookie.get('token'));

    if (to.meta.requireLogin && !store.state.authModule.token) {

        next(
            {
                path: '/login',
                query: {
                    ...to.query,
                    rdr: to.name === 'NotFound' ? '/secure' : to.path
                }
            }
        );

    } else if (to.name === 'Login' && store.state.authModule.token) {

        next('/secure');

    } else {

        next();

    }

});

router.afterEach(() => {

    if (store.state.authModule.token) {

        store.dispatch('defaultRequest', {
            api: 'GET_UTENZA',
            paylod: null,
            doResponse: () => {

                if (store.getters.isGuest) {
                    store.dispatch('activeSnackbar', {text: 'L\'utenza GUEST non è abilitata al BackOffice'});
                    store.dispatch('singout', location.href.split(/\?/)[1]);
                }

            },
            doClose: () => {
            }
        });

    }

});

export default router;
