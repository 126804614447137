const protectedRoute = [
    //Secure
    {
        path: '/secure',
        name: 'Secure',
        meta: {title: 'Secure', requireLogin: true},
        component: () => import('@/components/secure/Secure')
    },
    //Eventi
    {
        path: '/secure/eventi',
        name: 'Eventi',
        meta: {title: 'Eventi', requireLogin: true},
        component: () => import('@/components/secure/eventi/Eventi')
    },
    {
        path: '/secure/eventi/form',
        name: 'FormEvento',
        meta: {title: 'FormEvento', requireLogin: true},
        component: () => import('@/components/secure/eventi/FormEvento')
    },
    {
        path: '/secure/eventi/scheda',
        name: 'SchedaEvento',
        meta: {title: 'SchedaEvento', requireLogin: true},
        component: () => import('@/components/secure/eventi/SchedaEvento')
    },
    //Citta
    {
        path: '/secure/citta',
        name: 'Citta',
        meta: {title: 'Citta'},
        component: () => import('@/components/secure/citta/Citta')
    },
    {
        path: '/secure/citta/scheda',
        name: 'SchedaCitta',
        meta: {title: 'SchedaCitta', requireLogin: true},
        component: () => import('@/components/secure/citta/SchedaCitta')
    },
    //Tipologie
    {
        path: '/secure/tipologie',
        name: 'Tipologie',
        meta: {title: 'Tipologie', requireLogin: true},
        component: () => import('@/components/secure/tipologie/Tipologie')
    },
    {
        path: '/secure/tipologie/form',
        name: 'FormTipologia',
        meta: {title: 'FormTipologia', requireLogin: true},
        component: () => import('@/components/secure/tipologie/FormTipologia')
    },
    {
        path: '/secure/tipologie/scheda',
        name: 'SchedaTipologia',
        meta: {title: 'SchedaTipologia', requireLogin: true},
        component: () => import('@/components/secure/tipologie/SchedaTipologia')
    },
    //Lingue
    {
        path: '/secure/lingue',
        name: 'Lingue',
        meta: {title: 'Lingue', requireLogin: true},
        component: () => import('@/components/secure/lingue/Lingue')
    },
    {
        path: '/secure/lingue/form',
        name: 'FormLingua',
        meta: {title: 'FormLingua', requireLogin: true},
        component: () => import('@/components/secure/lingue/FormLingua')
    },
    {
        path: '/secure/lingue/scheda',
        name: 'SchedaLingua',
        meta: {title: 'SchedaLingua', requireLogin: true},
        component: () => import('@/components/secure/lingue/SchedaLingua')
    },
    //Clienti
    {
        path: '/secure/clienti',
        name: 'Clienti',
        meta: {title: 'Clienti', requireLogin: true},
        component: () => import('@/components/secure/clienti/Clienti')
    },
    {
        path: '/secure/clienti/form',
        name: 'FormCliente',
        meta: {title: 'FormCliente', requireLogin: true},
        component: () => import('@/components/secure/clienti/FormCliente')
    },
    {
        path: '/secure/clienti/scheda',
        name: 'SchedaCliente',
        meta: {title: 'SchedaCliente', requireLogin: true},
        component: () => import('@/components/secure/clienti/SchedaCliente')
    },
    //Contratti
    {
        path: '/secure/contratti',
        name: 'Contratti',
        meta: {title: 'Contratti', requireLogin: true},
        component: () => import('@/components/secure/contratti/Contratti')
    },
    {
        path: '/secure/contratti/form',
        name: 'FormContratto',
        meta: {title: 'FormCliente', requireLogin: true},
        component: () => import('@/components/secure/contratti/FormContratto')
    },
    {
        path: '/secure/contratti/scheda',
        name: 'SchedaContratto',
        meta: {title: 'SchedaCliente', requireLogin: true},
        component: () => import('@/components/secure/contratti/SchedaContratto')
    },
    //Utenti
    {
        path: '/secure/utenti',
        name: 'Utenti',
        meta: {title: 'Utenti', requireLogin: true},
        component: () => import('@/components/secure/utenti/Utenti')
    },
    {
        path: '/secure/utenti/form',
        name: 'FormUtente',
        meta: {title: 'FormUtente', requireLogin: true},
        component: () => import('@/components/secure/utenti/FormUtente')
    },
    {
        path: '/secure/utenti/scheda',
        name: 'SchedaUtente',
        meta: {title: 'SchedaUtente', requireLogin: true},
        component: () => import('@/components/secure/utenti/SchedaUtente')
    },
    //Workspace
    {
        path: '/secure/workspace',
        name: 'Workspace',
        meta: {title: 'Workspace', requireLogin: true},
        component: () => import('@/components/secure/workspace/Workspace')
    },
    {
        path: '/secure/workspace/form',
        name: 'FormWorkspace',
        meta: {title: 'FormWorkspace', requireLogin: true},
        component: () => import('@/components/secure/workspace/FormWorkspace')
    },
    {
        path: '/secure/workspace/scheda',
        name: 'SchedaWorkspace',
        meta: {title: 'SchedaWorkspace', requireLogin: true},
        component: () => import('@/components/secure/workspace/SchedaWorkspace')
    },
    //Errori
    {
        path: '/secure/errori',
        name: 'Errori',
        meta: {title: 'Errori', requireLogin: true},
        component: () => import('@/components/secure/errori/Errori')
    },
    //Profile
    {
        path: '/secure/profile',
        name: 'Profile',
        meta: {title: 'Profile', requireLogin: true},
        component: () => import('@/components/secure/profile/Profile')
    },
    //Incarichi
    {
        path: '/secure/incarichi',
        name: 'Incarichi',
        meta: {title: 'Incarichi', requireLogin: true},
        component: () => import('@/components/secure/profile/Incarichi')
    }
];

const publicRoute = [
    {
        path: '/',
        name: 'Home',
        meta: {title: 'Home', requireLogin: false},
        component: () => import('@/components/index/Index')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {title: 'Login', requireLogin: false},
        component: () => import('@/components/login/Login')
    },
    {
        path: '/app/changelog/:version',
        name: 'Changelog',
        meta: {title: 'Changelog', requireLogin: false},
        component: () => import('@/components/app/Changelog')
    },
    {
        path: '/app/privacy',
        name: 'Privacy',
        meta: {title: 'Privacy', requireLogin: false},
        component: () => import('@/components/app/Privacy')
    },
    {
        path: '/app/faq',
        name: 'Faq',
        meta: {title: 'Faq', requireLogin: false},
        component: () => import('@/components/app/Faq')
    },
    {
        path: '/app/s/eventi/:idEvento',
        name: 'EventoOpenApp',
        meta: {title: 'Changelog', requireLogin: false},
        component: () => import('@/components/app/EventoOpenApp')
    },
    {
        path: '/secure/**',
        name: 'NotFound',
        meta: {title: 'NotFound', requireLogin: false},
        component: () => import('@/components/aaaGenerics/commons/NotFound')
    },
    {
        path: '**',
        name: 'Redirect',
        redirect: '/'
    }
];

export default protectedRoute.concat(publicRoute);
